<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('KrishaHelpMenuCreate')"
    @edit="(id) => onEdit('KrishaHelpMenuEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'KrishaHelpMenu',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'KrishaHelpMenu',
      tableCaption: 'Меню справки',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Меню справки',
        },
      ],
      tableHeaders: [
        { text: 'Наименование', alias: 'name', order: 'name' },
        {
          text: 'Родительский элемент меню',
          alias: 'parentName',
          order: 'parentName',
        },
        { text: 'Ссылка', alias: 'link', order: 'link' },
        { alias: 'actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      helpMenuTopItems: (state) => state.common.helpMenuTopItems,
    }),
  },

  created() {
    this.getCommonList({ name: 'HelpMenuTopItems' });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
